<template>
    <div class="info-modal-wrapper" :class="{ 'active': showModal }">
      <p class="info-icon" @click="onClickInfo($event)">
        <wiser-icon icon="info" :style="{scale: iconSize}"></wiser-icon>
      </p>
      <div class="info-modal-content">
        <div style="font-size: 14px;">
          {{ t(`generic.page_infos.${props.pageKey}`, props.pageKey) }}
        </div>
        <div 
          v-if="!!props.videoLink"
          class="mt-2"
          >
          <Iframe
            :link="props.videoLink"
            width="390px" 
            height="216px"
            class="mx-auto"
          />
        </div>
      </div>
    </div>
</template>
<script setup>
import { onMounted, onUnmounted, defineProps, ref, computed } from "vue";
import WiserIcon from "@/components/icons/wiser-icons";
import { useI18n } from "vue-i18n";
import Iframe from "@/components/iframe/Iframe.vue";

const showModal = ref(false);

const props = defineProps({
  pageKey: {
    type: String,
    default: ''
  },
  videoLink: {
    type: String,
    default: null,
  },
  buttonSize: {
    type: String,
    default: 'md',
  }
});

const { t } = useI18n();

const iconSize = computed(() => {
  switch(props.buttonSize) {
    case 'lg':
      return '120%';
    case 'md':
      return '100%';
    case 'sm':
      return '80%';
    default:
      return '100%';
  }
});

const onClickInfo = (e) => {
  e.preventDefault();
  e.stopPropagation();
  showModal.value = !showModal.value;
};

const handleClickEvent = (evt) => {
  if (!evt.target.closest('.info-modal-wrapper') && showModal.value) {
    showModal.value = false;
  }
};

onMounted(async () => {
  document.addEventListener('click', handleClickEvent);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickEvent);
});
</script>
<style scoped lang="scss">
@import "~@/static/css/colors.scss";

.info-modal-wrapper {
  position: relative;
  .info-icon {
    cursor: pointer;
  }
  .info-modal-content {
    position: absolute;
    width: 420px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    left: 0;
    top: 20px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear;
    display: flex;
    flex-direction: column;
    padding: 12px;
    font-weight: 400;
    color: $text-dark-color;
    z-index: 50;
  }
  &.active {
    .info-modal-content {
      opacity: 1;
      visibility: visible;
      transform: translateY(5%);
    }
  }
}
</style>