<template>
  <label>
    {{ $t("generate_portfolio_advanced.compositions") }}
  </label>
  <a-row>
    <a-col
      :lg="12"
      :xs="24"
      class="p-2"
      v-for="(c, i) in portfolioLimitStore.compositions"
      v-bind:key="i"
    >
      <a-row align="middle" class="my-2">
        <a-col :xs="8" class="my-2">
          <a-tooltip :title="$t(`generate_portfolio.limits.${c.composition}`, c.composition)">
            <a-select
              v-model:value="c.composition"
              class="native-select"
              :placeholder="$t('generate_portfolio_advanced.categories')"
              style="width: 95%; height: 45px"
              @select="(value) => selectedCategory(value, i)"
            >
              <a-select-opt-group
                v-for="(fp, i) in Object.keys(listOfCompositions)"
                :placeholder="$t('generate_portfolio_advanced.categories')"
                v-bind:key="i"
                :label="$t(`generate_portfolio.limits.${fp}`, fp)"
              >
                <a-select-option
                  :value="f"
                  v-for="f in Object.keys(listOfCompositions[fp])"
                  v-bind:key="f"
                  :title="$t(`generate_portfolio.limits.${f}`, f)"
                  >{{ $t(`generate_portfolio.limits.${f}`, f) }}</a-select-option
                >
              </a-select-opt-group>
            </a-select>
          </a-tooltip>
        </a-col>
        <a-col :xs="8" class="my-2">
          <a-select
            v-model:value="c.operand"
            style="width: 95%; height: 45px"
            :placeholder="$t('generic.titles.operator')"
            class="native-select"
          >
            <a-select-option
              v-for="(f, i) in portfolioLimitStore.listOfComparers"
              :key="i + 'd'"
              :value="i"
              :title="$t(`operands.${f.name}`, f.name)"
            >
              {{ $t(`operands.${f.name}`, f.name) }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :xs="6" v-if="c.operand !== 0">
          <percent-input
            v-if="isPercent(c.composition)"
            v-model="c.percentage"
          ></percent-input>
          <a-input-number
            v-else
            v-model:value="c.percentage"
            :min="0"
            :style="{ height: '45px' }"
          />
        </a-col>
        <a-col :xs="1">
          <wiser-icon
            icon="minus"
            @click="portfolioLimitStore.removeComposiciones(i)"
          ></wiser-icon>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
  <a-row align="middle" class="my-2">
    <wiser-icon
      icon="plus"
      @click="portfolioLimitStore.addComposiciones()"
    ></wiser-icon>
  </a-row>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import WiserIcon from "@/components/icons/wiser-icons";
import { useAuthStore } from "@/stores/AuthStore";
import PercentInput from "@/components/wiseral/PercentInput";

const authStore = useAuthStore();
const portfolioLimitStore = usePortfolioLimitStore();

const listOfCompositions = computed(() => {
  return authStore.config["funds_filtrable_columns"]["compositions"];
});

const selectedCategory = (value, index) => {
  let categories = authStore.config["funds_filtrable_columns"]["compositions"];
  let chosenCategory = value;

  let fatherCategory = computed(() => {
    for (const category in categories) {
      if (chosenCategory in categories[category]) {
        return category;
      }
    }
    return null;
  });

  const category =
    authStore.config["funds_filtrable_columns"]["compositions"][
      fatherCategory.value
    ][value];

  const limitable = category["limitar_composiciones"];
  const firstLimit = category.limites[0];
  const secondLimit = category.limites[1];

  portfolioLimitStore.compositions[index].percentage = secondLimit;

  if (limitable) {
    if (firstLimit === secondLimit) {
      portfolioLimitStore.compositions[index].operand = 3;
    } else if (firstLimit === 0 && secondLimit === 1) {
      portfolioLimitStore.compositions[index].operand = 0;
    } else if (firstLimit === 0 && secondLimit <= 1) {
      portfolioLimitStore.compositions[index].operand = 2;
    } else if (firstLimit === 1 && secondLimit <= 1) {
      portfolioLimitStore.compositions[index].operand = 1;
    } else {
      portfolioLimitStore.compositions[index].operand = 0;
    }
  }
};

const isPercent = (field) => {
  if (authStore.config.master_funds_columns[field]) {
    return (
      authStore.config.master_funds_columns[field].format?.format === "percent"
    );
  }
  if (authStore.config.master_stocks_columns[field]) {
    return (
      authStore.config.master_stocks_columns[field].format?.format === "percent"
    );
  }
  if (authStore.config.master_others_columns[field]) {
    return (
      authStore.config.master_others_columns[field].format?.format === "percent"
    );
  }
  return false;
};

onMounted(async () => {
  if (portfolioLimitStore.compositions?.length === 0) {
    portfolioLimitStore.addComposiciones();
  }
});
</script>

<style scoped lang="scss">
label {
  margin: 30px 0 10px 0;
}

:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}
</style>
