<template>
  <a-input-number
    :min="0"
    style="height: 45px"
    :placeholder="$t('generic.titles.percent')"
    v-model:value="localValue"
    :formatter="(value) => `${value}%`"
    @change="onChange"
  />
</template>
<script setup>
import { defineEmits, defineProps, ref, watch } from "vue";

const props = defineProps({
  modelValue: { type: Number },
});
const localValue = ref(props.modelValue ? props.modelValue * 100 : "");
const emit = defineEmits(["update:modelValue", "selectedFunds"]);
const onChange = (newValue) =>
  emit(`update:modelValue`, newValue ? newValue / 100 : "");
watch(
  () => props.modelValue,
  (newValue) => (localValue.value = newValue ? newValue * 100 : "")
);
</script>
