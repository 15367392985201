<template>
  <label>
    {{ $t("generate_portfolio_advanced.weights") }}
  </label>
  <a-row>
    <a-col
      :lg="12"
      :xs="24"
      class="p-2"
      v-for="(w, i) in portfolioLimitStore.weights"
      v-bind:key="i"
    >
      <a-row align="middle" class="my-2">
        <a-col :xs="8" class="my-2">
          <a-tooltip :title="$t(`search_category_tooltips.${portfolioLimitStore.weights[i].weight}`)">
            <a-select
              v-model:value="portfolioLimitStore.weights[i].weight"
              :placeholder="$t('generate_portfolio_advanced.categories')"
              style="width: 95%; height: 45px"
              class="native-select"
              @select="(value) => selectedCategory(value, i)"
            >
              <a-select-option
                v-for="f in portfolioLimitStore.categories"
                v-bind:key="f"
                :value="f"
                :title="f"
              >
                {{ $t(`search_categories.${f}`, f) }}</a-select-option
              >
            </a-select>
          </a-tooltip>
        </a-col>
  
        <a-col :xs="8" class="my-2">
          <a-select
            v-model:value="portfolioLimitStore.weights[i].operand"
            style="width: 95%; height: 45px"
            :placeholder="$t('generic.titles.operator')"
            class="native-select"
          >
            <a-select-option
              v-for="(f, i) in portfolioLimitStore.listOfComparers"
              :key="i + 'd'"
              :value="i"
              :title="$t(`operands.${f.name}`, f.name)"
            >
              {{ $t(`operands.${f.name}`, f.name) }}
            </a-select-option>
          </a-select>
        </a-col>
  
        <a-col :xs="6" v-if="portfolioLimitStore.weights[i].operand !== 0">
          <percent-input
            v-model="portfolioLimitStore.weights[i].percentage"
          ></percent-input>
        </a-col>
        <a-col :xs="1">
          <wiser-icon
            icon="minus"
            @click="portfolioLimitStore.removeWeight(i)"
          ></wiser-icon>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
  <!-- </div> -->
  <a-row align="middle" class="my-2">
    <wiser-icon icon="plus" @click="portfolioLimitStore.addWeight()" />
  </a-row>
</template>

<script setup>
// import { onMounted } from "vue";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import WiserIcon from "@/components/icons/wiser-icons";
import { useAuthStore } from "@/stores/AuthStore";
import PercentInput from "@/components/wiseral/PercentInput";

const authStore = useAuthStore();
const portfolioLimitStore = usePortfolioLimitStore();

const selectedCategory = (value, index) => {
  const category = authStore.config.searches[value];
  const firstLimit = category.limits[0];
  const secondLimit = category.limits[1];

  portfolioLimitStore.weights[index].percentage = secondLimit;

  if (firstLimit === secondLimit) {
    portfolioLimitStore.weights[index].operand = 3;
  } else if (firstLimit === 0 && secondLimit === 1) {
    portfolioLimitStore.weights[index].operand = 0;
  } else if (firstLimit === 0 && secondLimit <= 1) {
    portfolioLimitStore.weights[index].operand = 2;
  } else if (firstLimit === 1 && secondLimit <= 1) {
    portfolioLimitStore.weights[index].operand = 1;
  } else {
    portfolioLimitStore.weights[index].operand = 0;
  }

  if (!portfolioLimitStore.categories.includes(value)) {
    portfolioLimitStore.categories.push(value);
  }
  portfolioLimitStore.categories.map(cat => {
    if (!portfolioLimitStore.weights.find(w => w.weight === cat)) {
      portfolioLimitStore.removeCategory(cat);
    }
  });
};

</script>

<style scoped lang="scss">
label {
  margin: 30px 0 10px 0;
}

:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}
</style>
