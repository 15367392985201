<template>
    <div class="frame-wrapper" :style="{ width: props.width, height: props.height }">
    <iframe
      ref="iframe"
      :src="props.link"
      :width="props.width"
      :height="props.height" 
      style="border: 1px solid lightgray;"
      allowfullscreen
    />
    <p class="video-description">{{ props.title }}</p>
    <a-spin class="loading-spinner" :indicator="indicatorSmall" v-if="loading"></a-spin>
  </div>
</template> 
<script setup>
import { defineProps, ref, h, onMounted, watch } from "vue";
import { LoadingOutlined } from '@ant-design/icons-vue';
const loading = ref(true);
const iframe = ref();
const props = defineProps({
  link: {
    type: String,
    default: ''
  },
  title: {    
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: '378px'
  },
  height: {
    type: String,
    default: '276px'
  },
});
const indicatorSmall = h(LoadingOutlined, {
  style: {
    padding: '0px 1px 4px 1px',
    fontSize: '18px',
    color: '#000'
  },
  spin: true,
});
watch(
  () => iframe.value,
  (iframeValue) => {
    if (iframeValue) {
      iframeValue.addEventListener("load", () => {
        loading.value = false;
      });
    }
  }
);
onMounted(() => {
  loading.value = true;
});
  </script>
  <style scoped lang="scss">
  .frame-wrapper {
    position: relative;
    .loading-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-left: 2px;
      padding-bottom: 3px;
    }
  }
  .video-description {
    font-size: 14px;
  }
  </style>