<template>
  <div class="w-100">
    <template v-if="collapsed === 1">
      <div class="flex align-items-center w-100">
        <label
          class="select-limits"
          @click="collapsed = 2"
        >
          {{ $t("generic.titles.limit_control") }} 
          <label class="dropdown-icon-label"></label>
        <wiser-icon
          icon="active-dot"
          v-if="
            portfolioLimitStore.areThereWeights ||
            portfolioLimitStore.areThereCompositions
          "
        /></label>
      </div>
    </template>
    <template v-else-if="collapsed === 2">
      <a-row class="w-100 mt-4" gutter="4">
        <a-col :xs="24" :md="8" :lg="10">
          <a-space>
            <sdButton
              size="small"
              type="primary"
              :outlined="!pesosSelected"
              @click="pesosSelected = true"
            >
              {{ $t("generate_portfolio_advanced.limit_weight_group") }}
              <wiser-icon
                icon="active-dot"
                v-if="portfolioLimitStore.areThereWeights"
              />
            </sdButton>
            <sdButton
              size="small"
              type="primary"
              :outlined="pesosSelected"
              @click="pesosSelected = false"
            >
              {{ $t("generate_portfolio_advanced.limit_compositions") }}
              <wiser-icon
                icon="active-dot"
                v-if="portfolioLimitStore.areThereCompositions"
              />
            </sdButton>
          </a-space>
        </a-col>
        <a-col :xs="24" :md="10" :lg="6" v-if="props.showSave">
          <a-select
            mode="select"
            :placeholder="$t('portfolio_limits.saved_limits_placeholder')"
            @select="handleSelectLimit"
            style="width: 100%; height: 37px;"
            class="native-select"
            :loading="portfolioLimitStore.loading"
          >
          <a-select-option
              v-for="saved_limit in portfolioStore.savedLimits"
              :key="saved_limit.id"
              :value="saved_limit.id"
              :title="saved_limit.name"
            >
              {{ saved_limit.name }}
            </a-select-option>
          </a-select>
        </a-col>  
        <a-col :xs="24" :lg="8">
          <sdButton
            class="float-right"
            size="small"
            type="danger"
            :outlined="true"
            :disabled="composiciones.length === 0"
            @click="resetFilters"
          >
          <Icon class="icon-single">
              <sdFeatherIcons type="rotate-ccw" size="22" />
              <span>{{ $t("generic.buttons.reset_filters") }}</span>
            </Icon>
          </sdButton>
        </a-col>
      </a-row>

      <div v-if="pesosSelected" class="inputs-mobile-positioning">
        <portfolio-limits-pesos></portfolio-limits-pesos>
      </div>
      <div v-else class="inputs-mobile-positioning">
        <portfolio-limits-composiciones></portfolio-limits-composiciones>
      </div>
      <wiser-icon
        class="float-end"
        icon="chevron up"
        @click="collapsed = 1"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps, onMounted } from "vue";
import PortfolioLimitsPesos from "@/components/wiseral/portfolio/PortfolioLimitsPesos.vue";
import PortfolioLimitsComposiciones from "@/components/wiseral/portfolio/PortfolioLimitsComposiciones.vue";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import WiserIcon from "@/components/icons/wiser-icons";

const portfolioLimitStore = usePortfolioLimitStore();
const portfolioStore = usePortfolioStore();

const props = defineProps({
  isCollapsed: {
    type: Number,
    default: 0,
  },
  showSave: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(["changeCollapsed", "selectLimit"])

const collapsed = ref(0);
const pesosSelected = ref(true);
const composiciones = ref([{}]);
// const pesos = ref([{}])

const handleSelectLimit = (e) => {
  const selectedLimit = portfolioStore.savedLimits?.find(item => item.id === e);
  if (selectedLimit) {
    const limitData = JSON.parse(selectedLimit.limits_json);
    console.log(limitData);
    portfolioLimitStore.categories = limitData?.categories ?? [];
    portfolioLimitStore.max_weight = limitData?.max_weight ?? null;
    portfolioLimitStore.apply_risk = limitData?.apply_risk ?? null;
    portfolioLimitStore.delete_weight_zero = limitData?.delete_weight_zero ?? false;
    portfolioLimitStore.only_my_bank = limitData?.only_my_bank ?? false;
    portfolioLimitStore.invest_amount = limitData?.invest_amount ?? 0;
    portfolioLimitStore.weights = limitData?.weights ?? [];
    portfolioLimitStore.compositions = limitData?.compositions ?? [];
    
    emit("selectLimit", { risk_level: limitData?.apply_risk ?? null , max_weight: limitData?.max_weight ?? null , only_my_bank :  limitData?.only_my_bank ?? false});
  }
}

const resetFilters = () => {
  portfolioLimitStore.resetFilters();
};
watch(
  () => collapsed.value,
  (newValue) => {
    emit("changeCollapsed", newValue);
  }
);
watch(
  () => props.isCollapsed,
  (value) => {
    collapsed.value = value;
  }
);
onMounted(() => {
  collapsed.value = props.isCollapsed;
});
</script>

<style scoped lang="scss">
@import "~@/static/css/colors.scss";

:deep(.ant-card-body) {
  padding: 8px !important;
}
.select-limits {
  color: $text-light-color;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  & .dropdown-icon-label {
    width: 6px;
    height: 6px;
    margin-left: 8px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(135deg);
  }
}
@media only screen and (max-width: 1149px) {
  .mobile-positioning {
    margin: 20px 0;
  }

  .inputs-mobile-positioning {
    margin: 50px 0;
  }
}
</style>
